export default {
  selectTdatoSistema (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.tsistema_tdatosist)
      .innerJoin(
        tables.tdato_sistema,
        tables.tsistema_tdatosist.idtdato_sistema.eq(tables.tdato_sistema.idtdato_sistema)
      )
      .innerJoin(
        tables.sistema,
        tables.tsistema_tdatosist.idtsistema.eq(tables.sistema.idtsistema)
      )
      .where(tables.sistema.idsistema.eq(idsistema))
      .exec()
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.datoSistema.row(id))
  },
}
